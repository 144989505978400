export function revealMoreCards(button_id, per_page, classname_base, start, remove_classname, show_all_button = false) {
	window.revealMoreCardsPage = (window.revealMoreCardsPage) ? window.revealMoreCardsPage + per_page : start;
	const page = window.revealMoreCardsPage;
	for(let i=0; i<per_page; i++) {
		let card = document.querySelector(`.${classname_base}${page+i}`);
		if(card) {
			card.classList.remove('display-none');
			card.classList.remove(remove_classname);
		}
		else {
			document.getElementById(button_id).style.display = 'none';
			if(show_all_button) document.getElementById(show_all_button).classList.remove('display-none');
			return;
		}
	}
	let next_card = document.querySelector(`.${classname_base}${page+per_page}`);
	if(next_card == undefined) {
		document.getElementById(button_id).style.display = 'none';
		if(show_all_button) document.getElementById(show_all_button).classList.remove('display-none');
	}
}

export function selectUpdateURLParam(el, key) {
	let url = new URL(document.URL);
	let params = new URLSearchParams(url.search);
	if(!el.value) params.delete(key);
	else params.set(key, el.value);
	let updated_url = `${document.location.origin}${document.location.pathname}`;
	if(`${params}` !== '') updated_url+= `?${params}`;
	document.location.assign(updated_url);
}

export function setActionAndSubmit(form_id) {
	const form = document.getElementById(form_id);
	const search_term = form.querySelector('[name="s"]');
	if(search_term.value.trim() == '') return;
	search_term.setAttribute('name','q'); // using 'q' instead of 's' search terms
	const action = form.querySelector('[name="action"]');
	const submitURL = action.value ?? '';
	action.remove(); // removes from URL params
	form.action = submitURL;
	form.submit();
}


export function goToLink(url, new_window = false) {
  if (new_window || window.cmd_pressed) window.open(url); // new window
  else window.location.assign(url); // same window
}

function goToMyLink(el) {
  window.go_to_el = el;
  var link = el.getElementsByTagName('a')[0];

  if (undefined === link) {
    console.log('no link found within element:', el);
    return;
  }

  var url = link.getAttribute('href');
  var target = link.getAttribute('target');
  var new_window = target && target == '_blank';

	goToLink(url, new_window);
}

export function setupGoToMyLink() {
	window.goToMyLink = goToMyLink;

	// keeps track of whether user is pressing cmd key
	window.cmd_pressed = false;

	document.body.addEventListener('keydown', function(e) {
		window.cmd_pressed = e.metaKey
	});

	document.body.addEventListener('keyup', function(e) {
		window.cmd_pressed = false;
	});
}

export function theContentBreak() {
	window.addEventListener('load', function() {
		const content_break = document.querySelector('#the-content-break'); // if we have a content_break
		if(!content_break) return;
		const h2s = content_break.parentElement.querySelectorAll('h2'); // and a second <h2> in the_content
		if(!h2s || !h2s[1]) return;
			h2s[1].before(content_break); // move content_break before the second <h2>
	});
}


export function initFadeInOnLoad() {
	window.addEventListener('load', fadeInOnLoad);
	window.setTimeout(fadeInOnLoad, 4000); // Fallback
}

function fadeInOnLoad() {
	if (document.body.classList.contains('is-faded-in')) return;
	var fiol_els = document.getElementsByClassName('fade-in-on-load');
	for (var i = 0; i < fiol_els.length; i++) {
		fiol_els[i].classList.add('fade-in');
	}
	if (fiol_els.length) setTimeout(function() { // Clean up, so the class styles don't override other styles
		var fiol_els = document.getElementsByClassName('fade-in-on-load');
		for (var i = 0; fiol_els.length > 0; i = 0) { // We work from the 0 index only...
			fiol_els[i].classList.remove('fade-in');
			fiol_els[i].classList.remove('fade-in-on-load'); // ...because this removes it from the 'fiol_els' element collection
		}
	}, 2000);
	document.body.classList.add('is-faded-in');
}

export function playIframeByID(iframe_id, overlay_id) {
	const iframe = document.getElementById(iframe_id);
	const overlay = document.getElementById(overlay_id);
	if(iframe) {
		iframe.src += "&autoplay=1";
	}
	if(overlay) overlay.classList.add('display-none');
}

export function touchToClickFix() {
	// Some mobile browsers register only a touch event on the first tap, and not a click event
	window.addEventListener('load', ()=>{
		let links = document.getElementsByTagName('a')
		for(let i=0; links[i]; i++) {
			// To prevent a click event from being double triggered, adding clickBlock to later check before calling click()
			links[i].addEventListener('click', (event)=> {
				event.target['clickBlock'] = true;
				setTimeout(()=> {
					event.target['clickBlock'] = false;
				}, 100);
			});
			// If needed, converts a touch event to a click event, if window.scrollY doesn't change between touchstart and touchend
			links[i].addEventListener('touchstart', (event)=>{
				event.target['scrollY'] = window.scrollY;
			});
			links[i].addEventListener('touchend', (event)=>{
				if(event.target['scrollY'] && event.target['scrollY'] == window.scrollY) {
					// If a click event hasn't been triggered recently, check for click being called in a moment...
					if(event.target['clickBlock'] == false) {
						setTimeout(()=>{
							// Double checks the browser didn't already trigger a click event on it's own after touchstart and touchend
							if(event.target['clickBlock'] == false) event.target.click()
						}, 10);
					}
				}
			});
		}
	});
}

export function scrollToByQuerySelector(selector, timeout = 10, animation_duration = 300) {

	if(window.scrollIgnore && window.scrollIgnore == true) return;

	const el = document.querySelector(selector);

	if(!el) {
		console.error('No element was found with the provided selector:', selector);
		return
	};

	window.scrollIgnore = true;
	setTimeout(() => {
		window.scrollIgnore = false;
		},
		timeout + animation_duration + 500
	);

	setTimeout(() => {
		// Get the offset of the sticky headers
		const page_header = document.querySelector('header.page-header');
		const header_sticky = document.querySelector('.is-page-header-sticky');
		const nav_header_offset = page_header ? page_header.offsetHeight : 0;
		const sticky_offset = header_sticky ? header_sticky.offsetHeight : 0;

		// Calculate the scroll position
		const padding = 20;
		const scrollTop = jQuery(el).offset().top - (nav_header_offset + sticky_offset + padding);

		jQuery('html, body').animate({
			scrollTop
		},
		animation_duration);
	},
	timeout);
}


export function removeAllClassnamesThenAdd(classname, el = null, timeout = 0) {
	setTimeout(() => {
		const others = document.querySelectorAll('.'+classname);
		others.forEach(other_el => {
			other_el.classList.remove(classname);
		});
		if(el) {
			el.classList.add(classname);
		}
	}, timeout);
}


export function keypressIntoOnclick(e) {
	var keycode = (e.keyCode ? e.keyCode : e.which);
	if (keycode == '13' || keycode == '32') { // enter or spacebar
		document.activeElement.click();
	}
}

export function pausePlay(video) {
	if(undefined === video) return;
	if(video.paused || video.ended) {
		video.play();
		video.setAttribute('title', 'Pause');
		video.classList.remove('is-paused');
	}
	else {
		video.pause();
		video.setAttribute('title', 'Play');
		video.classList.add('is-paused');
	}

}

export function isFullyVisible(element, plain = '') {
	if(!element) return;
  const rect = element.getBoundingClientRect();

	if(plain = 'x') {
		return (
			rect.left >= 0 &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		);
	}

	if(plain = 'y') {
		return (
			rect.top >= 0 &&
			rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
		);
	}

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}